@import '@material-design-icons/font';
@import url("../node_modules/ngx-toastr/toastr.css");

:root {
    --printed-page-width: 8.2in;
}

.app-background {
    background: #03a9f4;
}

.br-5 {
    border-radius: 5px;
}

.app {
    flex-direction: column;
    //background-color: #141414;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    overflow: hidden;
}
.h-86 {
    height: 86%;
}

.tooltip {
    .mdc-tooltip__surface {
        color: black;
        background-color: white;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
    margin: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(109, 81, 122, 0.479);
    border-radius: 20px;
}

.wrong-answer {
    border-width: 2px;
    border-style: solid;
    border-color: red;
    padding: 4px;
}

.green-icon {
    color: green;
}
.red-icon {
    color: red;
}

.link {
    color: var(--sonar-link-text-color);
    cursor: pointer;
    text-decoration: underline;
}

.disabled-link {
    color: var(--sonar-disabled-text-color);
    text-decoration: underline;
    pointer-events: none;
    cursor: pointer;
}
app-multiple-tabs-dialog .ng2-pdf-viewer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 4px;
  }