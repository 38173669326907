@import "bootstrap/scss/bootstrap";


@import "./variables";


.sonar-primary-btn,
.sonar-danger-btn,
.sonar-secondary-btn,
.sonar-success-btn,
.sonar-info-btn {
  @extend .btn;
  @extend .p-2;
  @extend .px-3;
  @extend .btn-outline-primary;

  border-radius: var(--sonar-border-radius);
  text-align: center // border: 1px solid black;
}

.sonar-primary-btn:disabled,
.sonar-danger-btn:disabled,
.sonar-secondary-btn:disabled,
.sonar-success-btn:disabled,
.sonar-info-btn:disabled {
  border-color: var(--sonar-disabled-border-color);
  color: var(--sonar-disabled-text-color);
  pointer-events: none;
  opacity: 0.7;
}

.sonar-button-icon-no-background {
  background-color: var(--sonar-default-icon-background-color);
  border: 0;
  line-height: 0;
}

.sonar-primary-button-color {
  color: var(--sonar-primary-color);
}

.sonar-secondary-button-color {
  color: var(--sonar-secondary-color);
}

.sonar-danger-button-color {
  color: var(--sonar-danger-color);
}

.sonar-button-icon-no-background:disabled {
  background-color: white;
  color: var(--sonar-disabled-text-color);
}




.sonar-filter-container {
  @extend .shadow-lg;
  @extend .p-2;
  @extend .my-1;
  @extend .sticky-top;
  border-radius: var(--sonar-border-radius);
  border: 1px solid rgba(0, 0, 0, 0.119);
  background-color: white;
  overflow: hidden;
}

// .sonar-primary-icon-btn,
// .sonar-secondary-icon-btn,
// .sonar-info-icon-btn,
// .sonar-success-icon-btn,
// .sonar-danger-icon-btn {

// }

* {
  // color: var(--sonar-primary-text-color);
  // font-family: var(--sonar-font-families);
}

.sonar-primary-icon-btn,
.sonar-danger-icon-btn,
.sonar-secondary-icon-btn,
.sonar-success-icon-btn,
.sonar-grey-icon-btn,
.sonar-info-icon-btn {
  @extend .p-2;
  height: 100%;
  border: 0px;
  line-height: 0px;
  border-radius: var(--sonar-icon-border-radius);
  text-align: center;
  background-color: var(--sonar-default-icon-background-color);
}

.sonar-primary-icon-btn:focus,
.sonar-danger-icon-btn:focus,
.sonar-secondary-icon-btn:focus,
.sonar-success-icon-btn:focus,
.sonar-grey-icon-btn:focus,
.sonar-info-icon-btn:focus {
  outline: none;
  outline-style: none;
}

.sonar-primary-icon-btn:hover,
.sonar-danger-icon-btn:hover,
.sonar-secondary-icon-btn:hover,
.sonar-success-icon-btn:hover,
.sonar-grey-icon-btn:hover,
.sonar-info-icon-btn:hover {
  color: var(--sonar-default-icon-text-color);
}



.sonar-primary-icon-btn,
.sonar-primary-btn {
  color: var(--sonar-primary-color);
  border-color: var(--sonar-primary-background-color);
}

.sonar-primary-icon-btn:hover,
.sonar-primary-btn:hover {
  background-color: var(--sonar-primary-background-color);
}

.sonar-danger-icon-btn,
.sonar-danger-btn {
  color: var(--sonar-danger-color);
  border-color: var(--sonar-danger-background-color);
}

.sonar-danger-icon {
  color: var(--sonar-danger-color);
}

.sonar-danger-icon-btn:hover,
.sonar-danger-btn:hover {

  background-color: var(--sonar-danger-background-color);
}

.sonar-secondary-icon-btn,
.sonar-secondary-btn {
  color: var(--sonar-secondary-color);
  border-color: var(--sonar-secondary-background-color);
}

.sonar-secondary-icon-btn:hover,
.sonar-secondary-btn:hover {

  background-color: var(--sonar-secondary-background-color);
}

.sonar-success-icon-btn,
.sonar-success-btn {
  color: var(--sonar-success-color);
  border-color: var(--sonar-success-background-color);
}

.sonar-success-icon {
  color: var(--sonar-success-color);
}
.sonar-success-icon-btn:hover,
.sonar-success-btn:hover {
  background-color: var(--sonar-success-background-color);
}

.sonar-info-icon-btn,
.sonar-info-btn {
  color: var(--sonar-info-color);
  border-color: var(--sonar-info-background-color);
}

.sonar-info-icon-btn:hover,
.sonar-info-btn:hover {
  background-color: var(--sonar-info-background-color);
}

.sonar-grey-icon-btn,
.sonar-grey-btn {
  color: var(---sonar-disabled-text-color);
  border-color: var(--sonar-disabled-border-color);
}
.sonar-grey-icon-btn:hover,
.sonar-grey-btn:hover {
  background-color: var(--sonar-disabled-border-color);
}

.sonar-primary-icon-btn:disabled,
.sonar-danger-icon-btn:disabled,
.sonar-secondary-icon-btn:disabled,
.sonar-success-icon-btn:disabled,
.sonar-grey-icon-btn:disabled,
.sonar-info-icon-btn:disabled {

  background-color: white;
  color: var(--sonar-disabled-text-color);
}

.sonar-quality-text,
.sonar-success-text,
.sonar-danger-text,
.sonar-secondary-text,
.sonar-technical-text {
  font-weight: 600;
}

.sonar-success-text {
  color: var(--sonar-success-text-color);

}

.sonar-danger-text {
  color: var(--sonar-danger-text-color);

}

.sonar-quality-text {
  color: var(--sonar-quality-text-color) !important;
}

.sonar-technical-text {
  color: var(--sonar-technical-text-color);
}

.sonar-secondary-text {
  color: var(--sonar-secondary-color)
}

.sonar-success-background {
  background-color: var(--sonar-success-background-color)
}

.sonar-info-background {
  background-color: var(--sonar-info-background-color)
}

.sonar-quality-background {
  background-color: var(--sonar-quality-background-color)
}

.sonar-danger-background {
  background-color: var(--sonar-danger-background-color)
}

.sonar-secondary-background {
  background-color: var(--sonar-secondary-background-color)
}

.sonar-technical-background {
  background-color: var(--sonar-info-background-color)
}

.sonar-severity-info-background {
  background-color: var(--sonar-severity-info-background-color) !important;
  color: white;
}

.sonar-severity-low-background {
  background-color: var(--sonar-severity-low-background-color) !important;
  color: white;
}

.sonar-severity-medium-background {
  background-color: var(--sonar-severity-medium-background-color) !important;
  color: white;
}

.sonar-severity-high-background {
  background-color: var(--sonar-severity-high-background-color) !important;
  color: white;
}

.sonar-severity-critical-background {
  background-color: var(--sonar-severity-critical-background-color) !important;
  color: white;
}
.sonar-severity-info-text{
  color:var(--sonar-severity-info-text-color);
}
.sonar-severity-low-text{
  color:var(--sonar-severity-low-text-color);
}
.sonar-severity-medium-text{
  color:var(--sonar-severity-medium-text-color);
}
.sonar-severity-high-text{
  color:var(--sonar-severity-high-text-color);
}
.sonar-severity-critical-text{
  color:var(--sonar-severity-critical-text-color);
}


.callout-severity-info{
  border-color: var(--sonar-severity-info-text-color);
}
.callout-severity-low{
  border-color: var(--sonar-severity-low-text-color);
}
.callout-severity-medium{
  border-color: var(--sonar-severity-medium-text-color);
}
.callout-severity-high{
  border-color: var(--sonar-severity-high-text-color);
}
.callout-severity-critical{
  border-color: var(--sonar-severity-critical-background-color);
}
a {
  color: var(--sonar-link-text-color);
}

a.MarkVisited:visited {
  color: var(--sonar-visited-link-text-color);
}
