:root {
  --sonar-primary-color: #214FFF;
  --sonar-primary-background-color: #17308E;

  --sonar-secondary-color: #CCBA41;
  --sonar-secondary-background-color: #dfc416;
  --sonar-quality-text-color: #fb3;

  --sonar-info-color: #579AC7;
  --sonar-info-background-color: #2594df;
  --sonar-technical-text-color: #4285f4;

  --sonar-danger-color: rgb(192, 10, 10);
  --sonar-danger-background-color: rgb(182, 4, 4);
  --sonar-danger-text-color: #ec3535;

  --sonar-success-color: rgb(7, 216, 35);
  --sonar-success-background-color: rgb(4, 182, 43);
  --sonar-success-text-color: #0dac4c;

  --sonar-disabled-text-color: grey;
  --sonar-disabled-border-color: gray;

  --sonar-default-icon-background-color: white;
  --sonar-default-icon-text-color: white;

  --sonar-severity-info-text-color: #7b7d85;
  --sonar-severity-info-background-color: #7b7d85;
  
  --sonar-severity-low-text-color: #23548d;
  --sonar-severity-low-background-color: #23548d;
  
  --sonar-severity-medium-text-color: #dbaf1d;
  --sonar-severity-medium-background-color: #dbaf1d;
  
  --sonar-severity-high-text-color: #e66c00;
  --sonar-severity-high-background-color: #e66c00;
  
  --sonar-severity-critical-text-color: #8e0101;
  --sonar-severity-critical-background-color: #8e0101;

  --sonar-border-radius: 20px;
  --sonar-icon-border-radius: 100%;

  --sonar-font-families: "Maven Pro";
  --sonar-primary-text-color: white;


  --sonar-disabled-opacity: 0.6;

  --sonar-link-text-color: #579AC7;
  --sonar-visited-link-text-color: #17308E;

  --sonar-filter-color: #6c757d;
  --sonar-filter-background-color: rgb(98, 105, 110);


  //to change from ts
  // document.documentElement.style.setProperty('--main-bg-color', '#fff');
}
